import React from 'react';
import { VehicleInfo } from './VehicleInfo';
import { Photos } from './Photos';

export const QuestionItem = ({ item, index }) => {
  function renderImages(images) {
    if (!images || !images.length) {
      return <p className="answer">N/A</p>;
    }

    return <Photos photos={item.images} />;
  }

  return (
    <div className="question-item">
      {item.type !== 'vehicle' && (
        <>
          <p className="question" data-number={index + 1}>
            {index + 1}. {item.question}
          </p>

          {item.description && (
            <p className="description">{item.description}</p>
          )}

          <p className="answer">
            {item.answer ? (
              <span>R: {item.answer}</span>
            ) : (
              item.type !== 'PHOTOS' && <span className="na">R: N/A</span>
            )}
          </p>
        </>
      )}
      {item.type === 'vehicle' && (
        <>
          <p className="question">{index + 1}. Información del vehículo</p>
          <VehicleInfo vehicle={item.rawVehicle} />
        </>
      )}
      {item.type === 'PHOTOS' && renderImages(item.images)}
    </div>
  );
};
