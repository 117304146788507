import React, { useState, useEffect } from 'react';
import M from 'materialize-css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { posthog } from 'posthog-js';
import Swal from 'sweetalert2';
import classNames from 'classnames';
import dayjs from 'dayjs';
import ReactGA from 'react-ga4';
import ReactTooltip from 'react-tooltip';
import {
  sanitizeAppStatus,
  phoneNumberFormatter
} from '../../../../utils/sanitizers';
import axiosAuth from '../../../../utils/axiosAuth';
import lastSeen from '../../../../utils/lastSeen';
import LoaderInner from '../../../common/loader-inner/LoaderInner';
import Button from '../../../common/button/Button';
// eslint-disable-next-line max-len
import DriverHeartbeats from '../../../common/driver-heartbeats/DriverHeartbeats';
import AirbagLoader from '../../../common/airbag-loader/AirbagLoader';
import HereMap from '../../../common/here-map/HereMap';
import whatsAppLogo from '../../../../assets/icons/whatsapp-logo-11.svg';
import './DriverProfile.scss';

function Spinner() {
  return (
    <div className="valign-wrapper" id="_hearbeats-loading_">
      <AirbagLoader />
    </div>
  );
}

function DriverProfile(props) {
  const { driver, mapPosition, fetchDriver, user } = props;
  const [loading, setLoading] = useState(false);
  const [heartbeats, setHeartbeats] = useState(null);
  const [loadingHeartbeats, setLoadingHeartbeats] = useState(true);
  const [messageTemplate, setMessageTemplate] = useState('lost-driver-1');

  useEffect(() => {
    // Fetch Raxel heartbeats info
    axiosAuth
      .get(`/api/users/heartbeats`, {
        params: { driverId: driver.id }
      })
      .then((res) => {
        const { heartbeats } = res.data;
        setHeartbeats(heartbeats);
        setLoadingHeartbeats(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingHeartbeats(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function changeUserAppStatus() {
    setLoading(true);

    axiosAuth
      .post(`/api/users/change-app-status`, {
        driverId: driver.id,
        pushToken: driver.pushToken
      })
      .then((res) => {
        fetchDriver();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        M.toast({
          html: 'Tuvimos un error, intenta de nuevo más tarde',
          classes: 'error-toast'
        });
        setLoading(false);
      });
  }

  function handleAppStatusClick() {
    Swal.fire({
      icon: 'question',
      title: `¿Deseas ${
        driver.appStatus === 'active' ? 'desactivar' : 'activar'
      } al conductor?`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: `<span class="dark-color-text">Si, ${
        driver.appStatus === 'active' ? 'desactivar' : 'activar'
      }</span>`,
      confirmButtonColor: '#f6c700'
    }).then((result) => {
      if (result.isConfirmed) {
        changeUserAppStatus();

        // Google analytics
        if (driver.appStatus === 'active') {
          ReactGA.event({
            category: 'driver_details',
            action: 'deactivate_driver_from_profile'
          });

          // Posthog
          posthog.capture('deactivate_driver_from_profile', {
            category: 'driver_details',
            isCustomEvent: true
          });
        } else {
          ReactGA.event({
            category: 'driver_details',
            action: 'activate_driver_from_profile'
          });

          // Posthog
          posthog.capture('activate_driver_from_profile', {
            category: 'driver_details',
            isCustomEvent: true
          });
        }
      }
    });
  }

  function renderLastSeenColor(dateTime) {
    let ret = 'bad';

    const today = dayjs();
    const logDate = dayjs(dateTime);
    const diff = today.diff(logDate, 'h');

    // Handle marker color
    if (diff < 24) ret = 'good';
    else if (diff >= 24 && diff <= 120) ret = 'regular';
    else if (diff > 120) ret = 'bad';

    return ret;
  }

  function getBatteryPerc(perc) {
    if (perc > 70) {
      return 'full';
    }
    if (perc > 30) {
      return 'med';
    }
    return 'low';
  }

  function getBatteryPercIcon(perc) {
    if (perc > 70) {
      return 'battery-full';
    }
    if (perc > 30) {
      return 'battery-half';
    }
    return 'battery-quarter';
  }

  function getLastLocationMarker(location) {
    if (location && location.lat && location.lon) {
      return {
        lat: driver.location.lat,
        lng: driver.location.lon,
        dateTime: driver.location.dateTime
      };
    }
    return null;
  }

  // Open sweetalert to confirm Post send whatsapp message
  function sendWhatsAppMessage() {
    Swal.fire({
      icon: 'info',
      title: `Mensaje de reconexión`,
      text: `Se enviará un WhatsApp con el mensaje especificado`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#f6c700',
      confirmButtonText: '<span class="dark-color-text">Enviar mensaje</span>'
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);

        // Google analytics
        ReactGA.event({
          category: 'driver_details',
          action: 'send_lost_wa_template'
        });

        // Posthog
        posthog.capture('send_lost_wa_template', {
          category: 'driver_details',
          isCustomEvent: true
        });

        // Make request
        axiosAuth
          .post(`/api/users/send-lost-driver-template`, {
            driverId: driver.id,
            driverName: driver.name,
            companyId: driver.company,
            template: messageTemplate
          })
          .then(() => {
            M.toast({
              html: '¡Mensaje enviado exitosamente!',
              classes: 'success-toast'
            });
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            let msg = 'Tuvimos un error, intenta de nuevo más tarde';
            if (
              err.response &&
              err.response.data &&
              typeof err.response.data === 'string'
            ) {
              msg = err.response.data;
            }
            M.toast({
              html: msg,
              classes: 'error-toast'
            });
            setLoading(false);
          });
      }
    });
  }

  function renderWhatsAppBtn() {
    const userDateTime = driver.location.dateTime;

    // Only show button to active drivers with last seen of more than 2 days
    if (dayjs().diff(dayjs(userDateTime), 'h') > 48 && user.isSam) {
      return (
        <div className="row">
          <div className="col s12">
            <div id="wa-msg-container">
              <div id="whatsapp-container">
                <label htmlFor="template">Seleccionar mensaje:</label>
                <select
                  name="template"
                  id="template"
                  className="select-message"
                  style={{ display: 'flex' }}
                  onChange={(e) => {
                    setMessageTemplate(e.target.value);
                  }}
                  value={messageTemplate}
                >
                  <option value="lost-driver-1">Reactivación 1</option>
                </select>
              </div>
              <div id="wa-btn-container">
                <Button
                  disabled={loading}
                  loading={loading}
                  variant="green-button"
                  className="small-button"
                  id="wa-btn"
                  type="submit"
                  onClick={sendWhatsAppMessage}
                >
                  Enviar mensaje
                  <img src={whatsAppLogo} id="whatsLogo" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  function renderDriverSwitch() {
    // Hide if there is no drivers information
    if (!driver || driver.firstLogin) {
      return null;
    }

    // Assign variables
    const { location, firstLogin, isDriving, appStatus, id } = driver;

    // Get last seen in hours
    const hoursSinceLastSeen = dayjs().diff(location.dateTime, 'h');

    return (
      <div
        className={classNames('switch-container', {
          'hide-switch': firstLogin,
          'disabled-switch':
            isDriving && appStatus === 'active' && hoursSinceLastSeen < 24
        })}
      >
        <input
          type="checkbox"
          className="custom-switch"
          id={'switch-' + id}
          checked={appStatus === 'active'}
          disabled={
            loading ||
            (isDriving && appStatus === 'active' && hoursSinceLastSeen < 24)
          }
          onChange={() => {
            if (!loading) {
              // Avoid active and driving in 24 hour window
              if (
                appStatus === 'active' &&
                isDriving &&
                hoursSinceLastSeen < 24
              ) {
                return;
              }
              handleAppStatusClick();
            }
          }}
        />
        <label
          htmlFor={'switch-' + id}
          className={`${appStatus === 'active' ? 'on' : 'off'}`}
          data-tip={
            isDriving && appStatus === 'active' && hoursSinceLastSeen < 3
              ? // eslint-disable-next-line max-len
                'El conductor no se puede desactivar ya que tiene un viaje en curso'
              : ''
          }
        ></label>
      </div>
    );
  }

  function renderDriverStatus(driver) {
    const loader = (
      <div className="preloader-wrapper small active">
        <div className="spinner-layer">
          <div className="circle-clipper left">
            <div className="circle"></div>
          </div>
          <div className="gap-patch">
            <div className="circle"></div>
          </div>
          <div className="circle-clipper right">
            <div className="circle"></div>
          </div>
        </div>
      </div>
    );

    const { firstLogin, isResting, appStatus, isSDKEnabled, status } = driver;

    // Handle disabled users
    if (status === 'inactive') {
      return <span className="status red-s">Deshabilitado</span>;
    }

    // First validation, check if user has logged in
    if (firstLogin) {
      return <span className="status red-s">Sin configurar</span>;
    }

    // Handle users that are resting only for TMS
    if (isResting) {
      return (
        <>
          <span className="status grey-s">En descanso</span>
          {renderDriverSwitch()}
        </>
      );
    }

    // Default is active or inactive
    return (
      <>
        <span
          className={classNames('status', {
            'green-s': appStatus === 'active',
            'grey-s': appStatus === 'inactive'
          })}
        >
          {loading && loader}
          {sanitizeAppStatus(appStatus, isSDKEnabled)}
        </span>
        {renderDriverSwitch()}
      </>
    );
  }

  // Driver info loading
  if (!driver) return <LoaderInner />;

  const { appInfo } = driver;

  return (
    <div id="_driver-profile_">
      <div className="row" id="top">
        <div className="col s12 xl7">
          <div id="profile">
            <div className="first-info-container">
              <div id="left-container">
                <p id="driver-name">
                  {driver.name}
                  <br />
                  {driver.lastName}
                  {driver.chargeLevel && (
                    <span id="battery-perc">
                      <FontAwesomeIcon
                        className={getBatteryPerc(driver.chargeLevel)}
                        icon={getBatteryPercIcon(driver.chargeLevel)}
                      />
                      <span>{driver.chargeLevel} %</span>
                    </span>
                  )}
                </p>
                <p id="last-seen">
                  {driver.location && driver.location.dateTime ? (
                    <React.Fragment>
                      Conexión más reciente hace
                      <span
                        id="last-seen-time"
                        className={`${renderLastSeenColor(
                          driver.location.dateTime
                        )}`}
                      >
                        {lastSeen(driver.location.dateTime)}
                      </span>
                    </React.Fragment>
                  ) : (
                    'Sin registros'
                  )}
                </p>
              </div>
              <div id="right-container">{renderDriverStatus(driver)}</div>
            </div>
            <div className="row" id="profile-info">
              {driver.phone ? (
                <div className="col s12 m6 info-content">
                  <label>Celular</label>
                  <p>
                    <FontAwesomeIcon
                      className="indicator"
                      icon={['fal', 'phone']}
                    />
                    <a href={`tel:${driver.phone}`}>
                      {phoneNumberFormatter(driver.phone)}
                    </a>
                  </p>
                </div>
              ) : (
                <div className="col s12 m6 info-content">
                  <label>Correo</label>
                  <p>
                    <FontAwesomeIcon className="indicator" icon="envelope" />
                    {driver.email}
                  </p>
                </div>
              )}
              <div className="col s12 m6 info-content">
                <label>Grupo</label>
                <p>
                  <FontAwesomeIcon
                    className="indicator"
                    icon={['fal', 'users']}
                  />
                  {driver.groups && driver.groups.length
                    ? driver.groups[0].name
                    : '-'}
                </p>
              </div>
              <div className="col s12 m6 info-content">
                <label>Versión de app</label>
                <p>
                  <FontAwesomeIcon
                    className="indicator"
                    icon={['fal', 'mobile']}
                  />
                  {appInfo && appInfo.versionApp
                    ? `${appInfo.versionApp} (${appInfo.versionCode})`
                    : '-'}
                </p>
              </div>
              <div className="col s12 m6 info-content">
                <label>Puntos</label>
                <p>
                  <FontAwesomeIcon
                    className="indicator"
                    icon={['fal', 'coins']}
                  />
                  {driver.coins ? Math.floor(driver.coins) : 0}
                </p>
              </div>
            </div>
            <div id="heartbeats-wrapper">
              {loadingHeartbeats ? (
                Spinner()
              ) : (
                <DriverHeartbeats
                  heartbeats={heartbeats}
                  appStatus={driver.appStatus}
                  isResting={driver.isResting}
                  lastSeen={
                    driver.location && driver.location.dateTime
                      ? driver.location.dateTime
                      : null
                  }
                  firstLogin={driver.firstLogin}
                  isDriving={driver.isDriving}
                  status={driver.status}
                />
              )}
            </div>
          </div>
        </div>
        <div className="col s12 xl5" id="map-container">
          <div id="map">
            <HereMap
              center={{
                lat: mapPosition.lat,
                lng: mapPosition.lon
              }}
              height={300}
              zoom={mapPosition.zoom}
              showTraffic={driver.appStatus === 'active' ? true : false}
              lastLocationMarker={getLastLocationMarker(driver.location)}
            />
          </div>
        </div>
      </div>
      {/* Tooltips */}
      <ReactTooltip />
    </div>
  );
}

export default DriverProfile;
