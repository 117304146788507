import { jsPDF } from 'jspdf';
import airbagLogo from './../../../assets/logos/airbag_logo_negro.png';
import M from 'materialize-css';

export const generatePDF = async (element, name, savePicker) => {
  if (!element) return;

  // create a new PDF document
  const doc = new jsPDF('p', 'pt', 'a4');
  // set font
  doc.setFont('arial');
  const topMargin = 60;

  // add classname to element for it
  // to have the correct styling
  element.classList.add('pdf-view');

  // function to add header to all the pages
  const addHeader = (doc) => {
    const pageWidth = doc.internal.pageSize.getWidth();
    const img = new Image();
    img.src = airbagLogo;

    return new Promise((resolve) => {
      img.onload = () => {
        for (let i = 1; i <= doc.getNumberOfPages(); i++) {
          doc.setPage(i);
          doc.addImage(img, 'PNG', pageWidth - 160, -10, 150, 100);
        }
        resolve();
      };
    });
  };

  if (savePicker) {
    await doc.html(element, {
      callback: async function (doc) {
        element.classList.remove('pdf-view');
        await addHeader(doc);

        // Convert PDF to a Blob
        const pdfBlob = doc.output('blob');

        // Call the save file picker
        try {
          const handle = await window.showSaveFilePicker({
            suggestedName: `${name}.pdf`,
            types: [
              {
                description: 'PDF Document',
                accept: { 'application/pdf': ['.pdf'] }
              }
            ]
          });

          // Write the PDF data to the selected file
          const writable = await handle.createWritable();
          await writable.write(pdfBlob);
          await writable.close();
          M.toast({
            html: 'Archivo descargado',
            classes: ''
          });
        } catch (err) {
          M.toast({
            html: 'Descarga cancelada',
            classes: ''
          });
          console.error('File saving cancelled or failed:', err);
        }
      },
      html2canvas: {
        scale: 0.82
      },
      autoPaging: 'text',
      margin: [topMargin + 10, 30, 40, 40]
    });
  } else {
    doc.html(element, {
      callback: function (doc) {
        element.classList.remove('pdf-view');
        doc.save(`${name}.pdf`);
      },
      html2canvas: {
        scale: 0.82
      },
      autoPaging: 'text',
      margin: [topMargin + 10, 30, 40, 40]
    });
    M.toast({
      html: 'Archivo descargado',
      classes: ''
    });
  }
};
