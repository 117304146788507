import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';

export const Photos = ({ photos }) => {
  return (
    <div>
      <div className="form-photos">
        <Carousel
          showThumbs={false}
          showIndicators={photos.length > 1}
          renderArrowPrev={(onClickHandler, hasPrev) =>
            hasPrev && (
              <span
                onClick={onClickHandler}
                style={{
                  position: 'absolute',
                  zIndex: 2,
                  top: 'calc(50% - 15px)',
                  width: 30,
                  height: 30,
                  cursor: 'pointer',
                  left: 15
                }}
              >
                <FontAwesomeIcon className="arrow" icon="arrow-left" />
              </span>
            )
          }
          renderArrowNext={(onClickHandler, hasNext) =>
            hasNext && (
              <span
                onClick={onClickHandler}
                style={{
                  position: 'absolute',
                  zIndex: 2,
                  top: 'calc(50% - 15px)',
                  width: 30,
                  height: 30,
                  cursor: 'pointer',
                  right: 15
                }}
              >
                <FontAwesomeIcon className="arrow" icon="arrow-right" />
              </span>
            )
          }
          renderIndicator={(onClickHandler, isSelected, index) => {
            if (isSelected) {
              return (
                <li
                  style={{
                    width: 12,
                    height: 12,
                    borderRadius: 20,
                    display: 'inline-block',
                    margin: '0 8px',
                    border: '1px solid #000',
                    background: '#f6c700'
                  }}
                />
              );
            }
            return (
              <li
                style={{
                  background: '#fff',
                  width: 10,
                  height: 10,
                  borderRadius: 20,
                  display: 'inline-block',
                  margin: '0 8px',
                  border: '1px solid #000'
                }}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                role="button"
                tabIndex={0}
              />
            );
          }}
        >
          {photos.map((photo, i) => (
            <div key={`${'mjm'}-${i}`}>
              {photo.base64 ? (
                <img
                  key={photo.fileName}
                  className="form-photo"
                  alt=""
                  src={`data:image/png;base64,${photo.base64.base64Data}`}
                />
              ) : null}
            </div>
          ))}
        </Carousel>
      </div>
      <div className="photo-grid">
        {photos.map((photo, index) => (
          <div key={`photo-${index}`} className="photo-item">
            <img
              src={`data:image/png;base64,${photo.base64.base64Data}`}
              alt={`Photo ${index + 1}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
