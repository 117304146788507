import React from 'react';
import dayjs from 'dayjs';
import fuelTypes from '../../../utils/fuelTypes';
import vehicleTypes from '../../../utils/vehicleTypes';
import carBrands from '../../../utils/carBrands';
import insuranceBrands from '../../../utils/insuranceBrands';

export const VehicleInfo = ({ vehicle }) => {
  if (!vehicle || !Object.keys(vehicle).length) {
    return (
      <div className="vehicle-info vehicle-not-found">
        <p className="not-found-message">
          No se encontró un vehículo con esas placas
        </p>
      </div>
    );
  }

  return (
    <div className="vehicle-info">
      <div className="vehicle-details">
        <div className="other-vehicle-info">
          <div>
            <span>Creado:</span>
            <span>
              {vehicle.created
                ? dayjs(vehicle.created).format('DD/MMM/YYYY')
                : '-'}
            </span>
          </div>
          <div>
            <span>UID</span>
            <span>{vehicle.uid || '-'}</span>
          </div>
          <div>
            <span>Nivel</span>
            <span>{vehicle.niv || '-'}</span>
          </div>
          <div className="vehicle-measurements">
            <div>
              <span>Alto:</span>
              <span>{vehicle.height || '-'}</span>
            </div>
            <div>
              <span>Ancho:</span>
              <span>{vehicle.width || '-'}</span>
            </div>
            <div>
              <span>Largo:</span>
              <span>{vehicle.length || '-'}</span>
            </div>
          </div>
        </div>
        <div className="basic-vehicle-info">
          <div>
            <span>Marca:</span>
            <span>
              {vehicle.brand
                ? carBrands.find((brand) => brand.value === vehicle.brand).name
                : '-'}
            </span>
          </div>
          <div>
            <span>Modelo:</span>
            <span>{vehicle.model || '-'}</span>
          </div>
          <div>
            <span>Color:</span>
            <span>{vehicle.color || '-'}</span>
          </div>
          <div>
            <span>Descripción:</span>
            <span>{vehicle.description || '-'}</span>
          </div>
          <div>
            <span>Combustible:</span>
            <span>
              {vehicle.fuel
                ? fuelTypes.find((type) => type.value === vehicle.fuel).name
                : '-'}
            </span>
          </div>
          <div>
            <span>Placas:</span>
            <span>{vehicle.plate || '-'}</span>
          </div>
          <div>
            <span>Tipo de vehículo:</span>
            <span>
              {vehicle.vehicleType
                ? vehicleTypes.find(
                    (type) => type.value === vehicle.vehicleType
                  ).name
                : '-'}
            </span>
          </div>
        </div>
        <div className="vehicle-insurance">
          <span>Información del seguro</span>
          <div>
            <span>Marca:</span>
            <span>
              {vehicle.insurance && vehicle.insurance.brand
                ? insuranceBrands.find(
                    (brand) => brand.value === vehicle.insurance.brand
                  ).name
                : '-'}
            </span>
          </div>
          <div>
            <span>Expira:</span>
            <span>
              {vehicle.insurance && vehicle.insurance.expiration
                ? dayjs(vehicle.insurance.expiration).format('DD/MMM/YYYY')
                : '-'}
            </span>
          </div>
          <div>
            <span>Póliza:</span>
            <span>
              {vehicle.insurance && vehicle.insurance.number
                ? vehicle.insurance.number
                : '-'}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
